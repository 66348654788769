:root {
  --media-size: 8em;
}

.card {
  margin: auto;
  padding: 0;
  display: flex;
  overflow: initial;
  position: relative;
  max-width: 500px;
  background: #ffffff;
  transition: 0.3s;
  align-items: flex-start;
  margin-left: auto;
  border-radius: 16px;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  background-color: var(--color-elevation-1);
}

.media {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px 16px 0 0;
  background-color: #d6d6d6;
  position: relative;
  justify-content: center;
  width: 100%;
  height: var(--media-size);
  margin-top: 0;
}

.content {
  padding: 1em;
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: var(--color-grey-dark);
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content h3 {
  font-size: 1em;
}

@media (min-width: 48em) {
  .card {
    padding: 0;
    align-items: center;
    flex-direction: row;
  }

  .media {
    width: 25%;
    justify-content: start;
    border-radius: 16px 0 0 16px;
  }
  .content {
    padding: 0 1em;
  }
}
