/* Color palette: https://coolors.co/424242-1973b8-40b1b0-4f5165-d1ccdc */
/* Possible other palette: https://coolors.co/1a1a1a-14213d-fca311-e5e5e5-ffffff */

:root {
  --color-background-hue: 0;
  --color-background: hsla(var(--color-background-hue), 0%, 97%, 1);
  --color-elevation-1: hsla(var(--color-background-hue), 0%, 92%, 1);
  --color-elevation-2: hsla(var(--color-background-hue), 0%, 87%, 1);

  --color-grey-dark: hsl(235, 12%, 35%);
  --color-grey-light: hsl(259, 19%, 83%);

  --color-text-light: hsl(0, 0%, 70%);
  --color-text-dark: hsl(218, 10%, 22%);
  --color-text-default: var(--color-text-dark);
  --color-text-sub: hsl(0, 0%, 0%, 0.6);

  --color-primary-hue: 0;
  --color-primary-s: 0%;
  --color-primary-l: 26%;
  --color-primary-text: var(--color-text-light);

  --color-secondary-hue: 206;
  --color-secondary-s: 76%;
  --color-secondary-l: 41%;
  --color-secondary-text: white;

  --color-tertiary-hue: 179;
  --color-tertiary-s: 47%;
  --color-tertiary-l: 47%;
  --color-tertiary-text: var(--color-text-light);

  --color-success-hue: 0;
  --color-success-s: 47%;
  --color-success-l: 47%;
  --color-success-text: var(--color-text-light);

  --color-warning-hue: 43;
  --color-warning-s: 47%;
  --color-warning-l: 47%;
  --color-warning-text: var(--color-text-light);

  --color-error-hue: 43;
  --color-error-s: 47%;
  --color-error-l: 47%;
  --color-error-text: var(--color-text-light);

  --color-primary: hsl(
    var(--color-primary-hue),
    var(--color-primary-s),
    var(--color-primary-l)
  );
  --color-primary-light: hsl(
    var(--color-primary-hue),
    var(--color-primary-s),
    calc(var(--color-primary-s) + 8%)
  );
  --color-primary-dark: hsl(
    var(--color-primary-hue),
    var(--color-primary-l),
    calc(var(--color-primary-s) - 12%)
  );

  --color-secondary: hsl(
    var(--color-secondary-hue),
    var(--color-secondary-s),
    var(--color-secondary-l)
  );
  --color-secondary-light: hsl(
    var(--color-secondary-hue),
    var(--color-secondary-s),
    calc(var(--color-secondary-s) + 8%)
  );
  --color-secondary-dark: hsl(
    var(--color-secondary-hue),
    var(--color-secondary-l),
    calc(var(--color-secondary-s) - 12%)
  );

  --color-tertiary: hsl(
    var(--color-tertiary-hue),
    var(--color-tertiary-s),
    var(--color-tertiary-l)
  );
  --color-tertiary-light: hsl(
    var(--color-tertiary-hue),
    var(--color-tertiary-s),
    calc(var(--color-tertiary-s) + 8%)
  );
  --color-tertiary-dark: hsl(
    var(--color-tertiary-hue),
    var(--color-tertiary-l),
    calc(var(--color-tertiary-s) - 12%)
  );

  /* Success */
  --color-success: hsl(
    var(--color-success-hue),
    var(--color-success-s),
    var(--color-success-l)
  );
  --color-success-light: hsl(
    var(--color-success-hue),
    var(--color-success-s),
    calc(var(--color-success-s) + 8%)
  );
  --color-success-dark: hsl(
    var(--color-success-hue),
    var(--color-success-l),
    calc(var(--color-success-s) - 12%)
  );

  /* Warning */
  --color-warning: hsl(
    var(--color-warning-hue),
    var(--color-warning-s),
    var(--color-warning-l)
  );
  --color-warning-light: hsl(
    var(--color-warning-hue),
    var(--color-warning-s),
    calc(var(--color-warning-s) + 8%)
  );
  --color-warning-dark: hsl(
    var(--color-warning-hue),
    var(--color-warning-l),
    calc(var(--color-warning-s) - 12%)
  );
  /* Error */
  --color-error: hsl(
    var(--color-error-hue),
    var(--color-error-s),
    var(--color-error-l)
  );
  --color-error-light: hsl(
    var(--color-error-hue),
    var(--color-error-s),
    calc(var(--color-error-s) + 8%)
  );
  --color-error-dark: hsl(
    var(--color-error-hue),
    var(--color-error-l),
    calc(var(--color-error-s) - 12%)
  );

  /* GRADIENT */
  --gradient-top: linear-gradient(
    0deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-right: linear-gradient(
    90deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-bottom: linear-gradient(
    180deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-left: linear-gradient(
    270deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-top-right: linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-bottom-right: linear-gradient(
    135deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-top-left: linear-gradient(
    225deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-bottom-left: linear-gradient(
    315deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );
  --gradient-radial: radial-gradient(
    var(--color-primary),
    var(--color-secondary),
    var(--color-grey-dark),
    var(--color-tertiary),
    var(--color-grey-light)
  );

  --color-hover: var(--color-secondary-light);
  --color-hover-text: var(--color-text-default);
}
