.detailsPanel summary {
  display: flex;
  align-items: center;
}

.detailsPanel summary:focus {
  outline: none;
}

.detailsPanel summary:focus {
  outline: none;
}

@media (min-width: 48em) {
  .detailsPanel summary::-webkit-details-marker {
    display: none;
  }
}
