/*
This is the parent `<div>` that contains the menu and the content area.
*/
#layout {
  position: relative;
  left: 0;
  padding-left: 0;
  width: auto;
}

/*
The content `<div>` is where all your content goes.
*/
.content {
  margin: 0 auto;
  padding: 0 2em;
  max-width: var(--size-content-width);
  margin-bottom: 50px;
  line-height: 1.6em;
}

.content-subhead {
  margin: 50px 0 20px 0;
  font-weight: 300;
  color: #888;
}
