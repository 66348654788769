/*
* The r-text-field component
*/

/*
The component will reset input browser styles
*/

.r-text-field {
  --uiTextFieldPlaceholderColor: var(--color-grey-light);

  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;

  border-radius: 0;
  border-style: solid;
}

.r-text-field::-webkit-input-placeholder {
  color: var(--uiTextFieldPlaceholderColor);
}

.r-text-field::-moz-placeholder {
  opacity: 1;
  color: var(--uiTextFieldPlaceholderColor);
}

.r-text-field:invalid {
  outline: none;
  box-shadow: none;
}

/*
* The r-button component
*/

/*
The component will reset button browser styles
*/

.r-button {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;

  font-family: inherit;
  font-size: inherit;
}

/* Remove the inner border and padding in Firefox. */

.r-button::-moz-focus-inner,
.r-button[type='button']::-moz-focus-inner,
.r-button[type='reset']::-moz-focus-inner,
.r-button[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/*
* The a-field component
*/

/*
 1. The --aFieldHeight option defines an input height. And this option is needed for calculations of a hint final position 
 2. The --aFieldHintFinalCoordinate option defines calculations of the final Y-axis coordinate
 3. The —aFieldPaddingLeft option defines left indent for a hint and query text
*/

.a-field {
  --uiaFieldHeight: var(--aFieldHeight);
  --uiaFieldHintFinalCoordinate: var(
    --aFieldHintFinalCoordinate,
    var(--uiaFieldHeight)
  );
  --uiaFieldPaddingLeft: var(--aFieldPaddingLeft);

  position: relative;
  padding-top: calc(
    var(--uiaFieldHintFinalCoordinate) - var(--uiaFieldHeight)
  ); /* 1, 2 */
}

.a-field__input {
  box-sizing: border-box;
  height: var(--uiaFieldHeight); /* 1 */
  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--uiaFieldPaddingLeft); /* 3 */
}

.a-field__input::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.a-field__input::-moz-placeholder {
  opacity: 0;
}

.a-field__input:focus::-webkit-input-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.a-field__input:focus::-moz-placeholder {
  opacity: 1;
}

.a-field__label-wrap {
  box-sizing: border-box;
  width: 100%;
  height: var(--uiaFieldHeight); /* 1 */

  pointer-events: none;
  cursor: text;

  position: absolute;
  bottom: 0;
  left: 0;
}

.a-field__label {
  position: absolute;
  left: var(--uiaFieldPaddingLeft); /* 3 */
  bottom: calc(50% - 0.5em);
  line-height: 1;
  pointer-events: none;
}

/* 
  I needed to create an animation onle Chromium broswers because:
  1. Firefox doesn't support a placeholder animation
  2. I couldn't make an correct animation at Edge
*/

@supports (-webkit-appearance: none) {
  .a-field__label {
    transition: bottom 0.2s cubic-bezier(0.9, -0.15, 0.1, 1.15),
      opacity 0.2s ease-out;
    will-change: bottom, opacity;
  }
}

/* If a user had inserted a text of query a hint will be hidden */

.a-field__input:not(:placeholder-shown) ~ .a-field__label-wrap .a-field__label {
  opacity: 0;
  bottom: 85%;
}

/* If the focal point is a field a hint will be displayed */

.a-field__input:focus ~ .a-field__label-wrap .a-field__label {
  opacity: 1;
  bottom: var(--uiaFieldHintFinalCoordinate); /* 2 */
}

/*
* the search component
*/

.search {
  --searchFieldHeight: 50px;
  --searchButtonSize: var(--searchFieldHeight);
  --textFieldPlaceholderColor: var(--color-text-default);

  --aFieldHeight: var(--searchFieldHeight);
  /* The 10px value has been calculated for this demo and you can change it */
  --aFieldHintFinalCoordinate: calc(var(--searchFieldHeight) + 10px);
  --aFieldPaddingLeft: 30px;

  color: var(--color-text-default);
  position: relative;
}

/* 
 1. I needed to set the currentColor value for correct displaying a border on iOS devices
*/

.search__input {
  width: 100%;
  border-width: 3px;
  border-color: currentColor; /* 1 */

  border-radius: 25px;
  background-color: transparent;

  font-size: 18px;
  color: currentColor;
}

.search__input:focus {
  outline: none;
}

.search__hint {
  font-size: 16px;
}

.search__button {
  color: currentColor;
}

.search__button:focus {
  outline: none;
}

.search__clear {
  width: 1em;
  height: 1em;
  font-size: var(--searchButtonSize);

  position: absolute;
  bottom: 0;
  display: none;
}

/* If a user starts typing a clearing button will be displayed */

.search__input:not(:placeholder-shown) ~ .search__clear {
  display: block;
}

/* If the focal point is a clearing button the hint will be displayed */

.search__clear:focus ~ .a-field__label-wrap .a-field__label {
  opacity: 1;
  bottom: var(--aFieldHintFinalCoordinate);
}

.search__clear-label {
  position: absolute;
  clip: rect(0 0 0 0);
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
}

.search__icon {
  fill: currentColor;
  display: block;

  box-sizing: border-box;
  width: 1.5em;
  height: 1.5em;
  padding: 0.25em;

  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
}

.search__button:focus .search__icon {
  box-shadow: 0 0 0 3px currentColor;
}

.search__icon-clear {
  font-size: 18px;
}

@media (max-width: 640px) {
  .search__input {
    padding-right: 50px;
  }

  .search__clear {
    right: 10px;
  }

  .search__submit {
    display: block;
    width: 100%;
    padding: 12px 15px;

    background-color: #fff;
    text-transform: uppercase;
    color: #5f27cd;
    border-radius: 25px;
    margin-top: 25px;
  }

  .search__submit:focus {
    box-shadow: 0 0 0 3px currentColor;
    color: #422380;
  }

  .search__icon-search {
    display: none;
  }
}

@media (min-width: 641px) {
  .search__input {
    padding-right: 115px;
  }

  .search__clear {
    right: var(--searchButtonSize);
  }

  .search__submit {
    width: 1em;
    height: 1em;
    font-size: var(--searchButtonSize);

    position: absolute;
    bottom: 0;
    right: 10px;
  }

  .search__submit-label {
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
  }

  .search__icon-search {
    font-size: 22px;
  }
}
