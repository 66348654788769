* {
  margin: 0;
  padding: 0;
  outline: none;
}

.widget {
  padding: 1em;
  /* position: relative;
  border-radius: 9px;
  margin: 1em 0;
  box-shadow: 0px 31px 35px -26px #080c21;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
}

.content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  text-align: center;
}

.content > *:last-child {
  margin-bottom: 1em;
}

.previewImage {
  height: 5em;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: var(--box-shadow);
  background-position: center;
}

@media (min-width: 48em) {
  .content {
    flex-direction: row;
  }

  .content > *:last-child {
    margin: 0;
  }
  .content > *:first-child {
    padding-right: 1em;
  }

  .previewImage {
    height: 10em;
    width: 66%;
    max-width: 36em;
  }
}
