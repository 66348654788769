@import './App.css';
@import './styles//colors.module.css';
/* @import './styles//colors.dark.module.css'; */

:root {
  --box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --box-shadow-bold: 1px 3px 2px -2px rgba(0, 0, 0, 0.2),
    1px 2px 2px 1px rgba(0, 0, 0, 0.14), 1px 2px 4px 1px rgba(0, 0, 0, 0.12);

  --size-content-width: 1000px;
}

.ease-transition {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

html {
  height: 100%;
  background-color: var(--color-background);
  color: var(--color-text-default);
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 800px) {
  html {
    font-size: 0.9em;
  }
}

/* Thumbnail settings */
@media (max-width: 750px) {
  html {
    animation-duration: 0.6s;
    font-size: 1em;
  }
}
