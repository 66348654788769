.recipeWrapper {
  display: grid;
  grid-template-areas:
    'header'
    'ingredients'
    'method';
}

.recipeWrapper > * {
  margin-bottom: 1em;
}

/* Header */

.recipeHeader {
  grid-area: header;
  margin-bottom: 1em;
}

.recipeHeader h1 {
  font-size: 2em;
  margin: 0 0 1em 0;
}

.recipeHeader h2 {
  font-size: 1.5em;
}

.recipeHeader ul {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  list-style: none;
}

.recipeHeader li {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* Ingredients */

.recipeIngredients {
  grid-area: ingredients;
  padding: 1em;
  box-shadow: var(--box-shadow-bold);
}

.recipeIngredients summary {
  display: flex;
  align-items: center;
}

.recipeIngredients table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.recipeIngredients tbody td {
  border-bottom: 1px inset var(--color-elevation-2);
}

.recipeIngredients th,
.recipeIngredients td {
  padding: 0.5em;
}

/* Method */

.recipeMethod {
  grid-area: method;
}

.recipeMethod h1 > span:hover {
  cursor: help;
}

.recipeMethod table {
  width: 100%;
}

.recipeMethod td:not(:first-child) {
  padding: 1em 0;
}

.recipeMethod tr td:first-child {
  padding-left: 1em;
  width: 2em;
}
.recipeStep {
  padding: 1em;
}

.recipeStep td {
  vertical-align: baseline;
}

.recipeStep:hover td:first-child {
  border-left: thin solid var(--color-secondary);
}

.recipeStep p {
  padding: 0.5em 0;
}

.recipeStep aside {
  display: flex;
  flex-flow: column;
  color: var(--color-text-sub);
}

.recipeStep aside > * {
  padding-right: 1em;
}

.ingredientTooltip {
  border-bottom: thin dashed;
  cursor: default;
}

@media (min-width: 48em) {
  .recipeWrapper * {
    --col-spacing: 1em;
  }

  .recipeHeader ul {
    border-bottom: ridge 2px var(--color-text-light);
  }

  .recipeWrapper {
    grid-template-areas:
      'header header header'
      'ingredients method method';
  }

  .recipeIngredients {
    padding-right: var(--col-spacing);
    height: fit-content;
  }

  .recipeMethod {
    padding-left: var(--col-spacing);
  }

  .recipeStep aside {
    flex-flow: row;
  }
}
