.header {
  box-shadow: var(--box-shadow);
  background: var(--color-primary);
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: var(--size-content-width);
  margin: 0 auto;

  padding: 0.5em;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.menu * {
  color: var(--color-primary-text);
  min-width: 1rem;

  text-decoration: none;
  white-space: nowrap;
}
.menu a {
  padding: 0.5em 1em 0.5em 0;
}

.menu li > a.active {
  color: var(--color-text-light);
}

.menu li *:hover,
.menu li *:focus {
  background: none;
  border: none;
  color: var(--color-secondary-light);
}

.brandLink {
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: white !important;
  font-weight: 400;
  font-size: 120%;
}

.actionArea {
  display: inline-block;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.actionItem {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  height: 100%;

  position: relative;
  box-sizing: border-box;
  list-style: none;
}

/*
* -- TABLET (AND UP) MEDIA QUERIES --
* On tablets and other medium-sized devices, we want to customize some
* of the mobile styles.
*/
@media (min-width: 48em) {
  /* We can align the menu header to the left, but float the
  menu items to the right. */
  .menu {
    justify-content: space-between;
  }
  .menu ul {
    float: right;
  }
}
